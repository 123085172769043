<template>
  <div class="home">
    <nav-bar :id="0"></nav-bar>
    <div class="top">
      <div class="banner">
        <img style="width:100%" :src="bannerImg" />
        <div class="search">
          <input v-model="searchValue" placeholder="请输入关键字(多个条件请用空格隔开)" @keyup.enter="onSearch" type="search" value="搜索" class="search-content" />
          <div class="search-divider"></div>
          <img class="search-icon" :src="require('@/assets/img/home/search-result.png')" />
          <van-icon v-if="searchValue" class="clear-btn" @click="onClear" name="close" color="#666" size="16" />
          <div class="search-btn" @click="onSearch">搜索</div>
        </div>
      </div>
      <div class="function">
        <div class="user-info" :style="tip !==''?'height:82px':''">
          <div style="display: flex;align-items:center;justify-content: space-between;">
            <div style="display:flex;align-items:center">
              <span style="color:#333;font-size:30px">Hi~</span><span style="margin-left:12px;font-size:16px">{{userName}}</span>
            </div>
            <img class="header" :src="headImg" />
          </div>
          <div v-if="tip !== ''"><span style="font-size:12px;color:red;">{{tip}}</span></div>
        </div>
        <div class="userOrgName">{{userOrgName}}</div>
        <div class="function-content">
          <van-grid :column-num="4" :border="false">
            <van-grid-item hoverClass="none" v-for="(item, index) in funcList" :key="index" @click="functionClick(item.nexturl, item.title)" :text="item.title" :icon="item.url"></van-grid-item>
          </van-grid>
        </div>
      </div>
    </div>
<!--    <div class="ad">-->
<!--      <van-swipe vertical :autoplay="3000" :duration="300" :show-indicators="false" class="ad-swiper">-->
<!--        <van-swipe-item v-for="(item, index) in adList" :key="index">-->
<!--          <img :src="item.src" alt="" class="ad-img">-->
<!--        </van-swipe-item>-->
<!--      </van-swipe>-->
<!--    </div>-->
    <div class="remind">
      <div class="remind-top">
        <div style="color:#333;font-size:16px;font-weight:bold">基础数据统计</div>
        <div style="color:#999;font-size:12px;display: flex;align-items: center;" @click="$router.push('/all-preview')">
          更多 <van-icon name="arrow" size="14" color="#D1D1D6"></van-icon>
        </div>
      </div>
      <van-grid :column-num="4" :border="false">
        <van-grid-item v-for="(item, index) in warnList" :key="index" @click="gotoIndex">
          <div style="color:#4581F8;font-weight:bold;font-size:20px">{{item.num}}</div>
          <div style="color:#999;font-size:12px;white-space:nowrap">{{item.title}}</div>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="keyNumber">
      <div class="number-top">
        <div style="color:#333;font-size:16px;font-weight:bold">关键数字</div>
      </div>
      <van-grid :column-num="4" class="numGrids" :border="false">
        <van-grid-item v-for="(item, index) in keyNumberList" :key="index" @click="gotoIndex">
          <div style="color:#333;font-weight:bold;font-size:20px">{{item.num}}</div>
          <div style="color:#666;font-size:12px;white-space:nowrap">{{item.title}}</div>
        </van-grid-item>
      </van-grid>
    </div>
    <pwd-verify ref="pwdVerify" @close="onClose" v-if="controledVerifyPwd"></pwd-verify>
  </div>
  </template>
  <script src='https://wpkgate-emas.ding.zj.gov.cn/static/wpk-jssdk.1.0.2/wpkReporter.js' crossorigin='true'></script>
  <script>
  import Vue from "vue";
  import {getImageStream} from '@/utils/index.js'
  import navBar from "@/components/navBar/navBar";
  import pwdVerify from '@/components/pwdVerify/pwdVerify'
  export default {
    components: {
      pwdVerify,
      navBar
    },
    data () {
      return {
        searchValue: '',
        userName: "管理员",
        userOrgName: "中国",
        pwdUrl: '',
        bannerImg: getImageStream("files/wx/images/jdgg/new-banner.png", process.env.VUE_APP_BASE_BACK_URL),
        headImg: getImageStream("files/wx/images/content/defaultHeader.png", process.env.VUE_APP_BASE_BACK_URL),
        funcList: [],
        warnList: [],
        adList: [],
        keyNumberList: [],
        controledVerifyPwd: false,   //控制敏感信息验证二次密码
        tempPageUrl: '',  //临时页面路径,
        tip:'' // 系统授权提示
      }
    },
    created () {
      this.userName = this.$globalData.userInfo.userName
      this.userOrgName = this.$globalData.userInfo.userOrgName || '中国'
      this.init()
    },
    methods: {
      init () {
        this.getRemind()
        this.getFunc()
        // this.getAdList()
        this.getKeyNumberList()
        // this.getAppValidity()
        aplus_queue.push({
          action: 'aplus.setMetaInfo',
          arguments: ['_hold', 'BLOCK']
        })
        // 设置会员昵称
        aplus_queue.push({
          action: "aplus.setMetaInfo",
          arguments: ["_user_nick", this.$globalData.ddUser.nickNameCn]
        })
        // 设置会员ID
        aplus_queue.push({
          action: "aplus.setMetaInfo",
          arguments: ["_user_id", this.$globalData.ddUser.accountId]
        })
        // 如采集用户信息是异步行为，需要先设置完用户信息后再执行这个START埋点
        // 此时被block住的日志会携带上用户信息逐条发出
        aplus_queue.push({
          action: 'aplus.setMetaInfo',
          arguments: ['_hold', 'START']
        })
      },
      onSearch () {
        this.$globalData.searchValue = this.searchValue
        this.$router.push('/search-result')
      },
      onClear () {
        this.searchValue = ''
      },
      getRemind () {
        this.$http({
          url: this.$http.adornUrl('/wxapp/statistics/jdgg/overview'),
          method: 'get'
        }).then(({data}) => {
          if (data && data.code === 0) {
            // alert(JSON.stringify(data.data))
            this.warnList = [{
              title: '村社总数', num: data.data.communityNum
            }, {
              title: '街镇总人口', num: data.data.resNum
            }, {
              title: '楼幢总数', num: data.data.buildingNum
            },
            {
              title: '房屋总数', num: data.data.houseNum
            },
            {
              title: '社工人数', num: data.data.socialWorkerNum
            }, {
              title: '网格员数', num: data.data.gridUserNum
            }, {
              title: '场所总数', num: data.data.placeNum
            }, {
              title: '设备总数', num: data.data.deviceNum
            },
            // {
            //   title: '居民匹配率', num: data.data.resAccurateNum
            // }
          ]
          } else {
            this.$toast.fail(data.msg)
          }
        })
      },
      getFunc () {
        this.$http({
          url: this.$http.adornUrl('/wxapp/sys/message/remind/config/myOrgListPass'),
          method: 'get',
          params: this.$http.adornParams({
            orgId: this.$orgId + ''
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.funcList = data.list.map(element => {
              return {
                url: getImageStream(element.icon, process.env.VUE_APP_BASE_BACK_URL),
                title: element.name,
                nexturl: element.target == 1 ? element.htmlUrl : '/appSSO?url=' + element.url + '&appId=' + element.menuId + '&appCode=' + element.code
                    + '&appName=' + element.name,
                color: element.color
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      },
      getAdList () {
        this.$httpBack({
          url: this.$httpBack.adornUrl('/app/application/ad/list'),
          method: 'post',
          params: this.$httpBack.adornParams({
            clientType: 5
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.adList = data.page.list.map(item => {
              return {
                src: getImageStream(item.filePath, process.env.VUE_APP_BASE_BACK_URL)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      },
      getKeyNumberList () {
        this.$http({
          url: this.$http.adornUrl('/wxapp/statistics/keyWord'),
          method: 'post',
          params: this.$http.adornParams({
            type: 3
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.keyNumberList = []
            for (var i in data.data) {
              if(i != 'label') {
                this.keyNumberList.push({
                  title: i,
                  num: data.data[i]
                })
              }
            }
          } else {
            this.$toast.fail(data.msg)
          }
        })
      },
      gotoIndex (url) {
        this.tempPageUrl = url
        this.$router.push(url)
      },
      functionClick (url, name) {
        this.tempPageUrl = url
        if (url.indexOf('http') !== -1) {
          window.location.replace(url)
        } else {
          if (name == '居民管理' || name == '房屋管理' || name == '出租屋') {
            this.$http({
              url: this.$http.adornUrl('/wxapp/secondVerifyFirst'),
              method: 'post'
            }).then(({data}) => {
              if (data.msg == '需要验证') {
                this.controledVerifyPwd = true
                this.$nextTick(() => {
                  this.$refs.pwdVerify.visible = true
                })
                this.pwdUrl = url
              } else {
                this.$router.push(url)
              }
            })
          } else {
            this.$router.push(url)
          }
        }
      },
      onClose (msg) {
        if (msg) {
          this.$router.push(this.pwdUrl)
        }else{
          this.$toast.fail('密码不正确,无法查看该模块信息')
        }
      },
      /**
       * 获取系统有效期情况
       */
      getAppValidity () {
        let appEffectiveDate = Vue.prototype.$globalData.systemEffectiveDate;
        if (appEffectiveDate) {
          let tip = ''
          if (appEffectiveDate.surplusDay >= 0 && appEffectiveDate.surplusDay < 60) {
            tip = `系统有效期：剩余${appEffectiveDate.surplusDay}天，请及时续费`
          } else if (appEffectiveDate.surplusDay < 0) {
            let passDay = appEffectiveDate.trial - Math.abs(appEffectiveDate.surplusDay)
            tip = `系统超期${Math.abs(appEffectiveDate.surplusDay)}天，${passDay}天后将停用，`
            if (appEffectiveDate.useType === 1) {
              tip += '请尽快续费'
            } else {
              tip += '请尽快开通'
            }
          }
          this.tip = tip
        }
      }
    }
  }
  </script>

  <style scoped>
  .home {
    padding-bottom: 166px;
  }
  .top {
    /*height: 988px;*/
  }
  .banner {
    position: relative;
    height: 430px;
    width: 750px;
  }
  .search {
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    width: 690px;
    height: 64px;
    background: #fff;
    border-radius: 20px;
  }
  .search-content {
    color: #666;
    padding-left: 76px !important;
    display: block !important;
    width: 520px !important;
    margin: 18px 122px 0 30px !important;
    background-color: transparent;
    border: none;
    font-size: 22px;
  }
  .search-content::placeholder {
    color: rgba(153, 153, 153, 0.50);
  }
  .clear-btn {
    position: absolute;
    top: 16px;
    right: 135px;
  }
  .search-divider {
    width: 2px;
    height: 32px;
    background: rgba(153, 153, 153, 0.30);
    border-radius: 2px;
    position: absolute;
    left: 80px;
    top: 16px;
  }
  .search-icon {
    position: absolute;
    top: 16px;
    left: 24px;
    width: 32px;
    height: 32px;
  }
  .search-btn {
    font-size: 24px;
    border-radius: 20px;
    text-align: center;
    position: absolute;
    right: 4px;
    top: 4px;
    width: 112px;
    height: 56px;
    line-height: 56px;
    background-color: #F24C48;
    color: #fff;
  }
  .title {
    position: absolute;
    top: 60px;
    left: 30px;
    color: #fff;
    font-size: 36px;;
  }
  .postName {
    position: relative;
    display: inline-block;
    top: 5px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    max-width: 400px;
    height: 30px;
    font-size: 28px;
  }
  .content-title {
    text-shadow: 0px 4px 20px rgba(0, 28, 178, 0.29);
    font-size: 84px;
    position: absolute;
    top: 268px;
    height: 180px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'youshebiaoti';
    color: transparent;
  }
  .content-title::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    content: attr(data-content);
    font-family: 'youshebiaoti';
    background: linear-gradient(to bottom, #fff, #abd0ff);
    -webkit-background-clip: text;
    background-clip: text; /*以文字的范围对背景进行裁剪*/
    color: transparent;
    text-shadow: none;
  }
  .function {
    position: relative;
    top: 0;
    width: 690px;
    left: 50%;
    overflow: hidden;
    padding-bottom: 40px;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 2px 12px 0px rgba(229, 229, 229, 0.5);
  }
  .user-info {
    height: 132px;
    padding: 24px;
    box-sizing: border-box;
  }
  .header {
    width: 92px;
    height: 92px;
  }
  .userOrgName {
    line-height: 60px;
    background-color: #F5F5F5;
    font-size: 24px;
    color: #999;
    padding: 0 24px;
  }
  .home >>> .van-grid-item__content {
    padding-bottom: 0;
  }
  .home >>> .van-icon__image {
    width: 68px !important;
    height: 68px !important;
  }
  .home >>> .van-grid-item__text {
    font-size: 24px !important;
    color: #333 !important;
  }
  .news {
    padding: 0 20px;
    position: relative;
    margin-top: 36px;
    width: 690px;
    height: 154px;
    left: 50%;
    overflow: hidden;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 2px 12px 0px rgba(229, 229, 229, 0.5);
  }
  .news-content {
    margin-top: 30px;
    height: 124px;
    overflow: hidden;
  }
  .swipe-content {
    height: 62px;
    overflow: visible;
  }
  .newsImg {
    margin-top: 20px;
    width: 66px;
    height: 104px;
  }
  .ad {
    margin-top: 18px;
    position: relative;
    width: 690px;
    height: 190px;
    left: 50%;
    transform: translateX(-50%);
  }
  .ad-swiper {
    width: 100%;
    height: 190px;
  }
  .ad-img {
    width: 690px;
    height: 190px;
  }
  .remind{
    margin-top: 28px;
    position: relative;
    width: 690px;
    left: 50%;
    overflow: hidden;
    padding-bottom: 40px;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 2px 12px 0px rgba(229, 229, 229, 0.5);
  }
  .remind-top {
    line-height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
  }
  .keyNumber{
    margin: 28px 0;
    position: relative;
    width: 690px;
    left: 50%;
    overflow: hidden;
    padding-bottom: 40px;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 2px 12px 0px rgba(229, 229, 229, 0.5);
  }
  .number-top {
    line-height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
  }
  .home >>> .van-hairline::after {
    border-top: none !important;
    border-right: none !important;
  }
  .home >>> .van-hairline {
    padding: 20px !important;
  }
  .home >>> .van-hairline--top::after {
    border: none;
  }
  .no-left::after {
    border-left: none !important;
  }
  .no-bottom::after {
    border-bottom: none !important;
  }
  </style>
